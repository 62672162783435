<!---/ Start Header-->
<section class="header">
  <div class="container">
       <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="#"><img src="{{image}}" style="height: 100px; width: auto; "></a>
           <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false">
                <i class="fa fa-bars"></i>
           </button>
           <div class="collapse navbar-collapse" id="navbarNav">
               <ul class="navbar-nav ml-auto text-right">
                   <li class="nav-item">
                     <a class="nav-link " href="https://www.kypn.ng">HOME </a>
                   </li>
                   <li class="nav-item">
                     <a class="nav-link" href="https://www.kypn.ng/about-us/">About Us</a>
                   </li>
                   <li class="nav-item">
                     <a class="nav-link" href="#">Programmes</a>
                   </li>
                   <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Board & Executives
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a class="dropdown-item" href="https://www.kypn.ng/patrons/">Our Patrons</a>
                      <a class="dropdown-item" href="https://www.kypn.ng/board-executive/">Our Board</a>
                    </div>
                  </li>
                   <li class="nav-item  active">
                       <a class="nav-link" href="/">Membership</a>
                   </li>
                   <li class="nav-item">
                       <a class="nav-link" href="https://www.kypn.ng/contact-us/">Contact Us</a>
                   </li>
               </ul>
           </div>
       </nav>
   </div>
 </section>

<router-outlet></router-outlet>
<section class="pt-5 pb-5 footer">
  <div class="container">
      <div class="row">
        <div class="col-lg-5 col-xs-12 about-company">
          <div><a class="navbar-brand" href="/"><img src="{{image}}" style="height: 100px; width: auto; "></a></div>
          <p class="pr-5 text-white-50">The Kogi Young Professionals Network (KYPN) is a Non - Partisan group that serves as a strategic platform for Kogi State professionals to contribute constructively towards decision making. </p>
          <p><a href="#"><i class="fa fa-facebook-square mr-1"></i></a><a href="#"><i class="fa fa-linkedin-square"></i></a></p>
        </div>
        <div class="col-lg-3 col-xs-12 links">
          <h4 class="mt-lg-0 mt-sm-3">Links</h4>
            <ul class="m-0 p-0">
              <li><a href="https://www.kypn.ng/about-us/">About Us</a></li>
              <li class="active"><a href="/">Membership</a></li>
              <li><a href="#">Programme</a></li>
              <li><a href="#">Donation</a></li>
              <li><a href="#">Events</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
        </div>
        <div class="col-lg-4 col-xs-12 location">
          <h4 class="mt-lg-0 mt-sm-4 pb-4">Location</h4>
          <ul class="address">
<li><i class="fa fa-bank"></i>&nbsp;&nbsp; Headquarters: Abuja, Nigeria </li>
<li><i class="fa fa-phone"></i>&nbsp;&nbsp; Calls only: 09097654328</li>
<li><i class="fa fa-whatsapp"></i>&nbsp;&nbsp; Whatsapp only: 09097654328</li>
<li><i class="fa fa-facebook"></i> &nbsp;&nbsp; facebook.com</li>
<li><i class="fa fa-twitter"></i>&nbsp;&nbsp; twitter.com</li>
<li><i class="fa fa-instagram"></i>&nbsp;&nbsp; instagram.com</li>
<li><i class="fa fa-envelope"></i>&nbsp;&nbsp; info@kypn.org.ng</li>
</ul>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col copyright">
          <p class=""><small class="text-white-50">Kogi Young Professionals Network (KYPN) &copy; 2019. All Rights Reserved.</small></p>
        </div>
      </div>
    </div>
</section>