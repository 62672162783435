<section class="title">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h2>
                Membership
                </h2>
            </div>
        </div>
    </div>
    </section>
    <section class="membership">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <h5 style="padding: 50px 0 20px 0;">How to be a member</h5>
                    <p>The Kogi Young Professionals Network (KYPN) is a Non - Partisan Organization that is open to all Kogi State professionals (by Birth, Citizenship or Marriage) and unemployed graduates, either male or female in Nigeria and Diaspora, within the age bracket of 18 - 45 years.</p>
                    <h5 style="padding: 20px 0;">Classes of Membership</h5>
                    <ul>
                        <li>Professionals in all careers, cutting across the: Public Service, Civil Service, Private Sector, Entrepreneurs, e.t.c. </li>
                        <li>Unemployed Graduates</li>
                        <li>Students of Tertiary Institutions</li>
                    </ul>
                </div>
                <div class="col-md-8" style="margin-bottom: 20px;">
                    <h5 style="padding: 50px 0 20px 0;">Membership Form</h5>
                   <!--      Wizard container        -->
            <div class="wizard-container">

                    <div class="card wizard-card" data-color="orange" id="wizardProfile">
                        <p class="pl-5 text-center">Fill form below to become a member of Kogi Young Professional Network (KYPN)</p>
                        <form action="" method="">
                    <div class="">
                                <div class="" id="about">
                                  <div class="row">
                                       <div class="col-sm-4 col-sm-offset-1">
                                         <div class="picture-container">
                                              <div class="picture">
                                    <img src="assets/img/default-avatar.png" class="picture-src"
                                    id="wizardPicturePreview" title=""/>
                                                  <input type="file" id="wizard-picture">
                                              </div>
                                              <h6>Choose Picture</h6>
                                          </div>
                                      </div>
                                      <div class="col-sm-6">
                                          <div class="form-group">
                                            <label>First Name <small>(required)</small></label>
                                            <input name="firstname" type="text" class="form-control" placeholder="Aderemi...">
                                          </div>
                                          <div class="form-group">
                                            <label>Last Name <small>(required)</small></label>
                                            <input name="lastname" type="text" class="form-control" placeholder="Smith...">
                                          </div>
                                      </div>
                                      <div class="col-sm-10 pl-5 col-sm-offset-1">
                                          <div class="form-group">
                                              <label>Email <small>(required)</small></label>
                                              <input name="email" type="email" class="form-control" placeholder="aderemi@emailaddress.com">
                                          </div>
                                      </div>
                                     
                                        <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                <div class="form-group">
                                                    <label>Date of birth <small>(required)</small></label>
                                                    <input name="dob" type="date" class="form-control" >
                                                </div>
                                            </div>

                                            <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                    <div class="form-group">
                                                        <label>Gender <small>(required)</small></label>
                                                        <select name="gender" class="form-control">
                                                            <option value="">Select a gender</option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">Female</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                        <div class="form-group">
                                                            <label>Phone No (Watts app No) <small>(required)</small></label>
                                                            <input name="Phone" type="text" class="form-control" placeholder="08111111123" >
                                                        </div>
                                                    </div>
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                    <div class="form-group">
                                                        <label>Country of Residence <small>(required)</small></label>
                                                            <select name="contry" class="form-control">
                                                                <option value="">Select a Country</option>
                                                                        
                                                            </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                    <div class="form-group">
                                                        <label>Educational Qualification <small>(required)</small></label>
                                                            <input name="edu" type="text" class="form-control" placeholder="Bsc." >
                                                    </div>
                                                </div>
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                        <div class="form-group">
                                                            <label>Occupation <small>(required)</small></label>
                                                                <input name="edu" type="text" class="form-control" placeholder="Occupation" >
                                                        </div>
                                                </div>
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                        <div class="form-group">
                                                            <label>Organization<small>(required)</small></label>
                                                                <input name="edu" type="text" class="form-control" placeholder="Organization" >
                                                        </div>
                                                </div>
                                                
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                        <div class="form-group">
                                                            <label>Category of Membership <small>(required)</small></label>
                                                                <select name="contry" class="form-control">
                                                                    <option value="">Select a Membership</option>
                                                                    <option value="Active Professionals">Active Professionals</option>
                                                                    <option value="Unemployed Professionals">Unemployed Professionals</option>
                                                                    <option value="Undergraduates">Undergraduates</option>
                                                                            
                                                                </select>
                                                        </div>
                                                </div>
                                                <div class="col-sm-10 pl-5 col-sm-offset-1">
                                                        <div class="form-group">
                                                            <label>National Identification Number <small>(Optional)</small></label>
                                                                <input name="national" type="text" class="form-control" placeholder="" >
                                                        </div>
                                                </div>

                                            
                                  </div>
                                </div>
                                
                                
                            </div>
                            <div class="wizard-footer height-wizard">
                                <div class="pull-right">
                                    <input type='button' class='btn btn-next btn-fill btn-warning btn-wd btn-sm' name='next' value='Next' />
                                       
                                </div>
    
                                
                                <div class="clearfix"></div>
                            </div>
    
                        </form>
                    </div>
                </div> <!-- wizard container -->
                </div>
            </div>
        </div>
    </section>
